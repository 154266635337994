@import "../../css/typography.css";
@import "../../css/variables.css";

.submit-btn {
    width: 140px;
    height: 40px;
    background: var(--blue-menu) 0% 0% no-repeat padding-box;
    background: #2D62ED 0% 0% no-repeat padding-box;
    opacity: 1;
    cursor: pointer;
}

.button-item {
    margin-right: 0px !important;
    width: 135px !important;
    height: 40px !important;
    background: var(--blue-menu) 0% 0% no-repeat padding-box !important;
    background: #2D62ED 0% 0% no-repeat padding-box !important;
    opacity: 1;
    font: var(--unnamed-font-style-normal)
    normal var(--unnamed-font-weight-600) 
    var(--unnamed-font-size-16)
    var(--unnamed-line-spacing-19) 
    var(--unnamed-font-family-montserrat) !important;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-light);
    text-align: center !important;
    margin-left: 10px !important;
    font: normal normal 600 13px/19px Montserrat !important;
    letter-spacing: 0px !important;
    color: #F5FBFF !important;
    opacity: 1;
    cursor: pointer;
    border-color: #2D62ED !important;
}

.button-item:hover {
    background: white 0% 0% no-repeat padding-box;
    color: #2D62ED;
    border-color: #2D62ED;
}


.divider {
    border: 1px solid var(--text);
    border: 1px solid #272D3B;
    opacity: 0.15;
}

.submit-btn:hover {
    background: white 0% 0% no-repeat padding-box;
    color: #2D62ED;
    border-color: #2D62ED;
}

.top-btns {
    flex-direction: row-reverse;
    display: flex;
    margin-bottom: 40px;
}