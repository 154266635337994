@import "../../css/typography.css";
@import "../../css/variables.css"; 

.marginRightAuto {
    margin-right: auto;
}
.greenPercentage {
    color: #5FAC3D !important;
}

.redPercentage {
    color: #CE4242 !important;
}

.bluePercentage {
    color: #00CCF2 !important;
}

.percentageDiv {
    margin-top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 44px;
}

.main-indicator-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    margin-left: 0 !important;
    font-style: normal !important;
    font-weight: 400;
    margin-top: 8px;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.sub-indicator-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    margin-left: 0 !important;
    font-style: normal !important;
    font-weight: 400;
    margin-top: -5px;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
    font-size: 20px;
}

.main-indicator-subtitle {
    text-align: left;
    margin-left: 0 !important;
    font-weight: normal;
    white-space: normal;
    overflow: hidden;
    display: none;
    margin-top: 10px;
    text-overflow: ellipsis;
    font: 14px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.main-indicator-date {
    text-align: left;
    margin-left: 0 !important;
    font-weight: normal;
    white-space: normal;
    overflow: hidden;
    margin-top: 10px;
    text-overflow: ellipsis;
    font: 14px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.arrow-img.a {
    fill: #2D62ED;
}

.indicator-holder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}

.indicatorParentHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media only screen and (max-width: 400px) {
    .indicator-card {
        min-width: auto !important;
    }
}

@media only screen and (max-width: 1100px) {
    .indicator-card {
        width: 100% !important;
        flex-basis: auto !important;
    }
}

@media only screen and (max-width: 1830px) and (min-width: 1100px) {
    .indicator-card  {
        flex-grow: initial !important;
        flex-basis: 49% !important;
    }
}

@media only screen and (min-width: 1830px) {
    .indicator-card {
        flex-grow: initial !important;
        flex-basis: 24% !important;
    }
}


  
  
.indicator-card {
    min-width: 360px;
    flex-basis: 25%;
    width: auto;
    margin-bottom: 56px;
    height: auto;
    display: flex;
    flex-grow: 1;   
    flex-direction: column;
    background: var(--white) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    
}

.indicator-title {
    width: 150px;
    height: 32px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    font: normal normal normal 26px/32px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.value-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-36)/var(--unnamed-line-spacing-44) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    font-weight: bold;
    font-size: 28px;
    font: "Montserrat";
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
    height: 44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.option-item:hover  {
    background-color: #2D62ED;
}

.option-item:hover > span  {
    color: white;
}

.option-title {
    width: 125px;
    height: 32px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    font: normal normal normal 14px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.percentage-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-36)/var(--unnamed-line-spacing-44) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    font: "Montserrat";
    margin-left: 5px;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
    margin-top: 5px;
    white-space: nowrap;
    margin-right: 10px;
}

.rotate180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.status-img {
    object-fit: contain;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    margin-bottom: 0px;
}

.settings-img {
    width: 6px;
    height: 20px;
    position: relative;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: 0px;
    cursor: pointer;
    margin-bottom: 0px;
}

@media only screen and (max-width: 450px) {
    .chart-title {
        margin-left: 0px !important;
    }

    .chart-card .topHolder {
        display: flex !important;
        flex-wrap: wrap;
        height: 84px !important;
        justify-content: flex-end;
    }

    
}

.backBtnChart {
    margin-left: -15px !important;
}

.topHolder {
    padding-top: 19px;
    padding-left: 25px;
    padding-right: 5px;
    width: 100%;
    display: inline-flex;
    height: 46px;
    align-items: center;
}

.topHolder > span {
    margin-right: auto;
}

.entityTitle {
    font: 20px Montserrat;
    font-weight: bold;
    letter-spacing: 0px;
    opacity: 1;
}

.noContentTitle {
    font-size: 26px;
    text-align: center;
}

.noContentHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.indicatorSkeleton {
    height: 80px !important;
    margin-left: 10px;
    margin-right: 10px;
}