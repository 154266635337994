@import "../../css/typography.css";
@import "../../css/variables.css";

.close-modal-img {
    width: 18px;
    height: 15px;
    margin-right: 33px !important;
    margin-top: 7px !important;
    margin-bottom: auto;
    opacity: 1;
    cursor: pointer;
}

#date-picker-static {
    width: 344px;
    height: 330px;
}

.close-img-filter {
    right: 20px;
    margin-top: 8px !important;
    position: absolute !important;
    margin-left: auto !important;
    width: 18px;
    height: 15px;
    opacity: 1;
    cursor: pointer;
}

.custom-switch {
    width: 52px !important;
    height: 38px !important;
    display: inline-flex !important;
    padding: 7px 3px !important;
    margin-left: 12px !important;
    z-index: 0 !important;
    overflow: hidden !important;
    position: relative !important;
    box-sizing: border-box !important;
    flex-shrink: 0 !important;
    vertical-align: middle !important;
}

.dialog-content {
    margin-top: 30px !important;
}

.drop-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-menu);
    text-align: left;
    font: normal normal bold 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #2D62ED;
    opacity: 1;
    cursor: pointer;
}


.filter-dialog {
    height: auto;
}

.filter-modal {
    width: 380px;
}

.filter-title {
    margin-right: auto;
    font: 18px Montserrat;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    letter-spacing: 0px;
    font-weight: normal;
    color: #272D3B;
    opacity: 1;
}

.MuiSwitch-root {
    width: 52px;
    height: 38px;
    display: inline-flex;
    padding: 7px 3px;
    margin-left: 12px;
    z-index: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
    vertical-align: middle;
}


.switch-selected {
    background: var(--blue-light) 0% 0% no-repeat padding-box;
    background: #F5FBFF 0% 0% no-repeat padding-box;
    opacity: 1;
}

.switch-not-selected {
    background: transparent url('../../static/switch_not_selected.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
}

.indicator-filter-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    width: 155px;
    margin-top: 2px;
    text-align: left;
    font: normal normal bold 16px/19px Montserrat;
    color: #2D62ED !important;
    letter-spacing: 0px;
    opacity: 1;
}

.alert-dialog-title {
    margin-right: auto;
    font-family: Montserrat;
    font-weight: bold !important;
    font-size: 26px !important;
}

