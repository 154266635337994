.inputField {
    margin-top: 25px !important;
}

.upload-user-profile {
    position: absolute;
    margin-top: 73px;
    margin-left: 47px;
    z-index: 1;
}

.simple-button {
    margin-right: 0 !important;
    width: 135px !important;
    height: 40px !important;
    background: var(--blue-menu) 0% 0% no-repeat padding-box !important;
    background: #2D62ED 0% 0% no-repeat padding-box !important;
    opacity: 1;
    font: var(--unnamed-font-style-normal)
    normal var(--unnamed-font-weight-600) 
    var(--unnamed-font-size-16)
    var(--unnamed-line-spacing-19) 
    var(--unnamed-font-family-montserrat) !important;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-light);
    text-align: center !important;
    margin-left: 0 !important;
    font: normal normal 600 13px/19px Montserrat !important;
    letter-spacing: 0px !important;
    color: #F5FBFF !important;
    opacity: 1;
    cursor: pointer;
    border-color: #2D62ED !important;
    margin-top: 25px !important;
}

.photoPartner {
    object-fit: cover;
    width: 130px !important;
    height: 130px !important;
    background-color: #bdbdbd;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 2.4rem !important;
    border-radius: 50%;
    align-self: center;
}

.subtitle {
    margin-top: 25px !important;
}

.divPhotoPartner {
    align-self: center;
    display: flex;
}

.camera-icon-partner {
    width: 37px !important;
    height: 37px !important;
    margin-top: 27px !important;
    color: white !important;
}

.noPhotoPartner {
    align-self: center;
    margin-left: 10px;
    font-family: 'Montserrat';
    color: red;
}

.flexSpan {
    display: flex;
    flex-direction: column;
}

.flexSpan2 {
    justify-content: center;
    position: relative;
    display: flex;
    align-self: center;
}

.simple-button:hover {
    background: white 0% 0% no-repeat padding-box;
    color: #2D62ED;
    border-color: #2D62ED;
}