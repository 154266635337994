.arrow-img {
    object-fit: cover;
    width: 10px;
    margin-left: 5px;
    fill: #2D62ED;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: auto;
    margin-bottom: auto;
}

.simple-popover {
    z-index: 1;
}
.dropHolder {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    height: 25px;
}

.listDropHolder {
    align-items: center;
    display: inline-table;
    flex-direction: row;
    margin-right: auto;
    height: 25px;
}

.drop-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-menu);
   
    text-align: left;
    font: normal normal bold 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #2D62ED;
    opacity: 1;
    cursor: pointer;
}

.drop-down {
    display: none;
    width: 100%;
    height: 56px;
    background: white;
    opacity: 1;
}

.drop-down-visible {
    display: block;
    width: auto;
    height: auto;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    z-index: 1;
    right: auto;
    margin-left: -2px;
    margin-top: 76px;
    cursor: pointer;
    padding-right: 10px;
}

.option-title {
    cursor: pointer;
}


.option-title-list {
    width: 125px;
    height: 32px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    margin-left: 0;
    margin-right: 10px;
    font: normal normal normal 14px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    cursor: pointer;
    opacity: 1;
}