@import "../../css/typography.css";
@import "../../css/variables.css";
#vertical-spacer {
    flex-direction: column;
    flex-grow: 1;
}

#arrow-img{
    width: 9px;
    object-fit: contain;
    height: 7px;
    fill: white;
    margin: 9px 10px 0px 8px;
}

.border {
    position: absolute;
    display: none;
    width: 1px;
    height: 25px;
    border: 1px solid var(--white);
    border: 1px solid #FFFFFF;
    margin-right: 20px;
    opacity: 1;
}

.border-show {
    position: absolute;
    display: block;
    width: 3px;
    height: 25px;
    border: 1px solid var(--white);
    border: 1px solid #FFFFFF;
    margin-right: 20px;
    opacity: 1;
    background: white;
    border-radius: 13%;
}

@media only screen and (max-width: 768px) {
    .side-menu {
        width: 0px !important;
        padding-right: 0px !important;
        display: none !important;
    }

    .closeMobileMenuBtn {
        color: white !important;
        margin-left: auto !important;
    }

    .sideMenuHeader {
        width: 100% !important
    }
}

.mobileMenu {
    width: 100% !important;
    display: flex !important;
    z-index: 1300;
}
  
.side-menu {
    overflow-x: hidden;
    background: var(--blue-menu);
    top: 0;
    left: 0;
    width: var(--side-menu-width);
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.sideMenuHeader {
   
    margin-top: 20px;
    width: var(--side-menu-width);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 11px;
    padding-right: 25px;
}


.headerImg {
    margin-right: auto;
    width: 33px;
    height: 32px;
    margin-bottom: 0px;
}
.titleHeader {
    font: normal normal 600 16px/19px Montserrat;
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 10px;
}

.item {
    cursor: pointer;
}

.holders{
    margin: 100px 0% 0% 0%;
}

.imageHeader {
    width: 40;
    height: 40;
}

.menu-selected {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.menu-not-selected {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.5;
}



.title {
    font: normal normal 600 16px/19px Montserrat;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    margin-top: 2px;
    padding-left: 10px !important;
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 10px;
}

.submenu-div {
    display: none;
    width: 136px;
    margin-left: 35px;
    margin-right: 0;
    text-align: center;
}

.submenu-div-show {
    display: block;
    margin-left: 60px;
    text-align: left;
    margin-bottom: 15px;
}

.submenu-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: center;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.5;
}

.submenu-selected {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: center;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}


.icon {
    width: 25px;
    height: 25px;
    margin-left: 15px;
}

.menu-holder {
    display: flex;
    flex-direction: column;
}

.skeletonMenu {
    width: 90%;
    height: 100px !important;
    margin-left: 12px;
    margin-bottom: -18px !important;
}

.buildVersion {
    font: inherit !important;
    font-family: var(--unnamed-font-family-montserrat);
    color: white;
    font-size: 13px !important;
    margin-left: 12px;
}