.addPCPageBtn {
    width: 252px;
    align-self: flex-end;
    margin-bottom: 20px !important;
    margin-top: -20px !important;
}

.cancelAddBtn {
    width: 75px;
    align-self: flex-start;
    margin-bottom: 20px !important;
    margin-top: -20px !important;
}