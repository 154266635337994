@import "../css/variables.css";
* {
    scrollbar-width: thin;
    scrollbar-color: #ecedef white;
  }
  
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  *::-webkit-scrollbar-track {
    background:white;
  }
  .side-menu::-webkit-scrollbar-track {
    background:var(--blue-menu);
  }
  
  .side-menu::-webkit-scrollbar-thumb {
    border-radius: inherit;
    border: 3px solid rgba(0,0,0,0.2);
    background-color: rgba(0, 0, 0, 0);
  }
  *::-webkit-scrollbar-thumb {
    background-color: #ecedef;
    border-radius: 100px;
    border: 3px solid #ecedef;
  }
