@import "../../css/typography.css";
@import "../../css/variables.css";

.action-div {
    width: 20%;
    margin-top: 20px;
    margin-left: 10%;
}

.spacer {
    flex-grow: 1;
}

.MuiIcon-root {
    overflow: visible !important;
}

@media only screen and (max-width: 768px) {
    .top-bar {
        padding-right: 0px !important;
        left: 0px !important;
    }

    .hamburguer {
        position: absolute !important;
        left: 5px;
    }
}

@media only screen and (min-width: 769px) {
    .top-bar {
        left: var(--side-menu-width) !important;
        padding-right: var(--side-menu-width) !important;
    }

    .hamburguer {
        display: none !important;
    }
}

.hamburguer {

}

.top-bar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    display: inline-flex;
    background: var(--white) 0% 0% no-repeat padding-box !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    opacity: 1;
}

.search-border {
    border: 1px solid var(--text);
    border: 1px solid #272D3B;
    width: 35%;
    height: 30px;
    margin-top: 20px;
    border-radius: 25px;
    opacity: 1;
}


.header {
    display: inline-flex;
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    margin-left: 10%;
    margin-top: 10%;
}

.notification-icon {
    width: 24px;
    height: 30px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;

}

.pointer {
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .logout-icon {
        position: absolute !important;
        right: -20px !important;
    }
}

.logout-icon {
    width: 28px;
    height: 30px;
    padding: 22px !important;
    margin-right: 40px !important;
    opacity: 1;
}

.search-icon {
    width: 19px;
    height: 19px;
    margin: 5px 5px 5px 15px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
}

.search-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    font-size: 16px;
    position: absolute;
    margin: 5px 5px 5px 5px;
    font: normal normal normal 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.topBarTitle {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;   
    margin-left: 46px !important;
    font-weight: bold  !important;
    font-size: 26px  !important;
    letter-spacing: 0px  !important;
    color: #272D3B;
    opacity: 1;
}