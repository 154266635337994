/* custom tabulator css */
.table, .tabulator{
    color:#272D3B;
    font-family: 'Montserrat';
    font-size:14px
}

.tabulator {
    width: 100%;
}


.tabulator .tabulator-cell{
    color:#272D3B;

}

.tabulator .tabulator-header{
    text-transform: uppercase;
    border-bottom: 1px solid #eaeaea;
}

.tabulator-row .tabulator-cell, .tabulator .tabulator-header .tabulator-col {
    border:none;
}

.tabulator .tabulator-row.tabulator-selectable:hover {
    background-color: #f3f1f1;
}

.tabulator-cell {
    margin:8px 0px;
}

@media only screen and (max-width: 480px) {
    .table-title-bar {
        flex-wrap: wrap !important;
    }
}

.table-title-text{
    white-space: nowrap;
    font-size:26px;
    font-weight: bold;
}

.table-title-bar{
    margin-bottom:42px;
    display: flex;
}


.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-content .tabulator-col-sorter .tabulator-arrow{
border:none !important;
height: 10px;
width: 10px;
}

.tabulator-col-sorter{
    background-repeat: no-repeat;
    background-position: center;
}

.table.padded-box.box-shadow.no-filter .tabulator .tabulator-header .tabulator-col {
    border: none;
}

.table.padded-box.box-shadow.no-filter .tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
    display: none;
}

.tabulator-header .tabulator-col.tabulator-sortable{
    height: auto !important;
}

.table-title-text{
    width: auto;
    display: inline-block;
}

.table-title-buttons{
    display: flex !important;
    margin-left: 10px;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    margin-top: 3px;
    flex-wrap: wrap;
}

.tabulator .tabulator-footer{
    padding: 10px 10px;
    text-align: center;
}

.table-title-button {
    display: inline-flex;
    margin-left: 10px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-menu);
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #2D62ED;
    opacity: 1;
    cursor: pointer;
}

.table-icon {
    margin-bottom: unset;
    margin-right: 5px;
    vertical-align: middle;
}

.tabulator .tabulator-footer .tabulator-page:active {
    outline: none !important;
    border: none !important;
}

.tabulator .tabulator-footer .tabulator-page:target {
    outline: none !important;
    border: none !important;
}

.tabulator .tabulator-footer .tabulator-page:focus {
    outline: none !important;
    border: none !important;
}

.tabulator .tabulator-footer .tabulator-page {
    height: 25px;
    padding: 0px 10px 0px 10px;
    border:none;
}

.tabulator .tabulator-footer .tabulator-page.active {
    color: white;
    background: #2D62ED;
}

.tabulator .tabulator-footer .tabulator-page:hover {
    color: white !important;
    background: #2D62ED !important;
}

.tabulator-header-filter input{
    border:none;
}

.tabulator-header-filter{
    border-bottom: 1px solid #2D62ED;
    margin-bottom: 6px !important;

}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
    background-color: unset;
}

.tableSkeleton {
    height: 200px !important;
    margin-top: -40px !important;
    margin-bottom: -25px !important;
}

.tabulator-edit-select-list{
    font-family: 'Montserrat';
}