.checkItem {
    
}

.checkHolder {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: scroll;
    margin-left: 10px;
    margin-right: 20px;
    padding-top: 16px;
}

.title-name {
    font: 16px Montserrat;
    font-weight: normal;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.checkHolder-indicator {
    height: 55%;
    display: inline-block;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: auto;
    padding-top: 14px;
    margin-right: 3px;
}

.checkError {
    margin-left: 25px;  
    position: absolute;
    color: red;
}