.profileSpan {
    padding-left: 7px !important;
}

.profileTitle {
    color: "white";
    font: normal normal 600 16px/19px Montserrat;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 5px;
}

.profileIcon {
    color: white;
    padding-left: 12px;
    font-size: 2.4rem !important;
}

.container {
    cursor: pointer;
    padding-left: 5px !important;
    display: flex !important;
    align-items: center;    
}

.logoutIcon {
    margin-left: 15px;
}