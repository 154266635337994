.profileSpan {
    padding-left: 7px !important;
    align-self: center;
}

.profileTitle {
    color: "white";
    font: normal normal 600 16px/19px Montserrat;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 5px;
}

.profileIcon {
    color: white;
    padding-left: 12px;
    font-size: 2.4rem !important;
}

.container {
    cursor: pointer;
    padding-left: 5px !important;
    display: flex !important;
    align-items: center;    
}

.userPhoto {
    object-fit: cover;
    width: 35px !important;
    height: 35px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 2.4rem !important;
    border-radius: 50%;
    align-self: center;
    margin-left: 7px;
}

.border-show-profile {
    align-self: center;
    position: absolute;
    width: 3px;
    height: 25px;
    border: 1px solid var(--white);
    border: 1px solid #FFFFFF;
    opacity: 1;
    background: white;
    border-radius: 13%;
}