.addPCBtn {
    width: 230px;
    align-self: flex-end;
    margin-bottom: 20px !important;
    margin-top: -20px !important;
}

.addDateBtn {
    margin-top: 13px !important;
    width: 278px;
    align-self: flex-start !important;
}

.dateLine {
    margin-top: -25px;
    margin-bottom: 20px;    
    display: flex;
}

.textDate {
    margin-left: 10px !important;
}

.dateDate {

}