@import "../css/variables.css";
#paperLogin {
    background: var(--blue-white);
}
.formContainerGrid {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    max-width: 300px;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

#imgContainer {
    position: relative;
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

#imgContainer div {
    position: absolute;
    width: 200px;
    height: 200px;
    background:var(--blue-menu);
    border-radius: 50%;
}

#imgContainer img {
    width: 200px;
    height: 200px;
    position: absolute;
    padding: 30px;
}

#formLogin .inputLogin {
    margin: 10px;
    height: 70px;
}

#loginBtn {
    background:var(--blue-menu);
    margin-top: 20px;
    margin: 10px;
}

.forgotSpan {
    font-size: 14px;
    align-self: center;
    font-family: 'Montserrat';
}

.forgotSpan:hover {
    color: var(--blue-menu);
    cursor: pointer;
}