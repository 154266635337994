.chart {
    font-family: 'Montserrat';
    font-size: 14px;
}

.chartParentHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}



@media only screen and (max-width: 1090px) {
    .chartParentHolder .chart-card {
        width: 100% !important;
        flex-basis: auto !important;
    }
}

@media only screen and (min-width: 1090px) {
    .chartParentHolder .chart-card {
        flex-grow: initial !important;
        flex-basis: 49% !important;
    }
}

.chartTitleANdIcon {
    display: flex;
    margin-right: 10px !important;
    align-items: center;
}

.chart-card {
    flex-grow: 1;
    margin-bottom: 40px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
}

.chart-title {
    font-family: 'Montserrat';
    padding-right: 20px;
    margin-left: 10px;
    width: auto;
    margin-right: auto;
}

.chart .dropHolder {
    margin-right: 20px;
}

.chart-holder {
    height: 450px;
}

.recharts-default-legend li {
    margin-bottom: 0px !important;
}

.noContentTitle {
    font-size: 26px;
    text-align: center;
}

.noContentHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.chartSkeleton {
    height: 120px !important;
    margin-left: 10px;
    margin-right: 10px;
}

.recharts-legend-wrapper{
    overflow: auto;
    height: 87% !important;
}