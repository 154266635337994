@import "../../css/typography.css";
@import "../../css/variables.css";

.detailHeader {
    margin-bottom: 30px !important;
}

.camera-icon {
    width: 13px;
    height: 12px;
    color: white !important;
}

.paperHolder {
    display: flex;
    font-family: 'Montserrat';
    
}

.camera-img {
    width: 15px !important;
    height: 13px !important;
}

.span {
    font-size: 14px;    
}

.detailHeaderImg {
    object-fit: cover;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-top: 29px;
    margin-left: 35px;
}

.detailItemsHolder {
    height: 100%;
    width: auto;
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    align-self: center;
    margin: 20px;
}

.detailItemOne {
    font-size: 12px;   
    font-weight: bold;
}

.detailItemTwo {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.detailItemHolder {
    display: flex;
}

.detailMainItemsHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-left: 20px;
}

.detailMainItemsHolder > span {
    font-weight: bold;
}

.paperHolderMargin {
    margin-bottom: 56px;
}


.photoImg {
    width: 85px !important;
    height: 85px !important;
    margin-left: 15px;
    background-color: #bdbdbd;
    padding-left: 3px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 2.4rem !important;
}

.MuiAvatar-colorDefault {
    color: #2D62ED;
    background-color: white;
}

.MuiAvatar-fallback {
    width: 85px !important;
    height: 65px !important;
    margin-top: 0;
    margin-bottom: 5px;
}


.upload-img {
    position: absolute;
    margin-top: 73px;
    z-index: 1;
}

#upload-photo{
    z-index: 0;
}