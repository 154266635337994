.custom-text-margin {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.custom-date-padding {
    display: inline-flex;
}

.smaller{
    width: 33%;
    padding-right: 10px !important;
}