@import "../../css/typography.css";
@import "../../css/variables.css";

.add-file-img {
    margin-top: auto;
    margin-bottom: auto;

}

.add-file-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-menu);
    text-align: left;
    margin-left: 5%;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #2D62ED;
    opacity: 1;
}

.button-item {
    width: 135px;
    height: 40px;
    background: var(--blue-menu) 0% 0% no-repeat padding-box;
    background: #2D62ED 0% 0% no-repeat padding-box;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-light);
    text-align: center;
    margin-right: 10px;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #F5FBFF;
    opacity: 1;
    cursor: pointer;
    border-color: #2D62ED;
}

.button-item:hover {
    background: white 0% 0% no-repeat padding-box;
    color: #2D62ED;
    border-color: #2D62ED;
}

.container {
  width: auto;
  clear: both;
}

.container input {
  width: 100%;
  clear: both;
}

.close-img-modal {
    width: 22px;
    height: 22px;
    position: relative;
    left: 75%;
    margin-top: auto;
    margin-bottom: auto;
    color: #2D62ED 0% 0% no-repeat padding-box !important;
    opacity: 1;
    cursor: pointer;
}

.MuiDialog-paperWidthSm {
    height: auto;
}

.MuiDialogContent-root {
    margin: 8px 0;
}


.input-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0) !important;
    color: var(--text) !important;
    text-align: left !important;
    background-color: transparent !important;
    border: 0px solid !important;
    margin-top: 0px !important;
    margin-left: 5% !important;
    margin-bottom: 0 !important;
    font: normal normal medium 16px/19px Montserrat !important;
    letter-spacing: 0px !important;
    color: #272D3B !important;
    opacity: 1 !important;
}

.input-txt:focus, input:focus{
    outline: none;
}

.label + .MuiInput-formControl {
    margin-top: 0px !important;
}

.divider {
    border: 1px solid var(--text);
    border: 1px solid #272D3B;
    opacity: 0.15;
}


.modal {
    width: 55%;
    height: 539px;
    position: absolute;
    margin: 5% 5% 0% 20%;
    z-index: 1;
    background: var(--white) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #272D3B58;
    opacity: 1;
}

.modal-hide {
    display: none;
}


.modal-show {
    display: block;

}

.modal-title {
    width: 90%;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    font: normal normal bold 26px/32px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.span-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    font: normal normal bold 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
    outline: none;
}



.submit-btn {
    width: 135px;
    height: 40px;
    color: #2D62ED;
    margin: 0 !important;
    position: absolute !important;
    top: 80%;
    left: 50%;
    transform: translate(-50%, 85%) !important;
    background: #2D62ED 0% 0% no-repeat padding-box !important;
    opacity: 1;
}

.submit-btn:hover {
    background:  #2D62ED 0% 0% no-repeat padding-box !important;;
    color: white !important;
    border-color: #2D62ED !important;
}

.top-layout {
    width: 100%;
    display: inline-flex;
    margin: 25px;
}