.confirmBtn {
    margin-left: 10px !important;
    height: 50px !important;
    margin-top: 5px !important;
}

.firstLine {
    display: flex;
}

.buttonDiv {
    display: flex;
    justify-content: space-between;
}

.photoPartner {
    object-fit: cover;
    width: 130px !important;
    height: 130px !important;
    background-color: #bdbdbd;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 2.4rem !important;
    border-radius: 50%;
    align-self: center;
}

.subtitle {
    margin-top: 25px !important;
}

.divPhotoPartner {
    align-self: center;
    display: flex;
}

.camera-icon-partner {
    width: 37px !important;
    height: 37px !important;
    margin-top: 27px !important;
    color: white !important;
}

.noPhotoPartner {
    align-self: center;
    margin-left: 10px;
    font-family: 'Montserrat';
    color: red;
}

.flexSpan {
    display: flex;
    flex-direction: column;
}

.flexSpan2 {
    justify-content: center;
    position: relative;
    display: flex;
    align-self: center;
}