.addDateBtn {
    margin-top: 13px !important;
    width: 278px;
    align-self: flex-start !important;
}

.dateLine {
    margin-top: -25px;
    margin-bottom: 20px;    
    display: flex;
}

.textDate {
    margin-left: 10px !important;
}
