.containerGrid {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-right: auto;
    margin-top: auto;
    height: 100%;
}


#imgContainer {
    position: relative;
    width: 200px;
    height: 200px;
    background:var(--blue-menu);
    border-radius: 50%;
    margin-bottom: 20px;
}


#imgContainer img {
    width: 200px;
    height: 200px;
    position: absolute;
    padding: 30px;
}