@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
.loadingBar {
    width: 100%;
    position: fixed;
    z-index: 100000;
    top: 0;
}
@media only screen and (max-width: 768px) {
    :root {
        --custom-margin: 20px !important;
    }
}

:root {
    /* side menu */
    --side-menu-width: 220px;
    /* Main Containar Margins */
    --custom-margin: 67.5px;
    

    /* Colors: */
    --blue-menu: #2D62ED;
    --white: #FFFFFF;
    --dark-mode-3: #455060;
    --blue-primary: #00CCF2;
    --dark-mode-2: #29313A;
    --blue-light: #F5FBFF;
    --text: #272D3B;
    --ce4242-estado-3: #CE4242;
    ---fda820-estado-2: #FDA820;
    --5fac3d-estado-1: #5FAC3D;
    --unnamed-color-00d9a6: #00D9A6;
    --unnamed-color-ffcf64: #FFCF64;
    --unnamed-color-ff3465: #FF3465;
    --unnamed-color-003c4d: #003C4D;
    --velvet: #7D00B5;
    --unnamed-color-008cb3: #008CB3;
    --pink: #FF007C;
    --blue-light-2: #F4F7FE;
    --dark-mode-1: #181D23;
    
    /* Font/text values */
    --unnamed-font-family-montserrat: Montserrat;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-600: 600px;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-size-12: 12px;
    --unnamed-font-size-14: 14px;
    --unnamed-font-size-16: 16px;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-20: 20px;
    --unnamed-font-size-26: 26px;
    --unnamed-font-size-36: 36px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-line-spacing-15: 15px;
    --unnamed-line-spacing-18: 18px;
    --unnamed-line-spacing-19: 19px;
    --unnamed-line-spacing-22: 22px;
    --unnamed-line-spacing-24: 24px;
    --unnamed-line-spacing-30: 30px;
    --unnamed-line-spacing-32: 32px;
    --unnamed-line-spacing-44: 44px;
    }
    
    /* Character Styles */
    .unnamed-character-style-1 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: 600px;
    font-weight: var(--unnamed-font-weight-600);
    font-size: 16px;
    font-size: var(--unnamed-font-size-16);
    line-height: 19px;
    line-height: var(--unnamed-line-spacing-19);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-2 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: 600px;
    font-weight: var(--unnamed-font-weight-600);
    font-size: 20px;
    font-size: var(--unnamed-font-size-20);
    line-height: 24px;
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-3 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: medium;
    font-weight: var(--unnamed-font-weight-medium);
    font-size: 16px;
    font-size: var(--unnamed-font-size-16);
    line-height: 19px;
    line-height: var(--unnamed-line-spacing-19);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-4 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: medium;
    font-weight: var(--unnamed-font-weight-medium);
    font-size: 18px;
    font-size: var(--unnamed-font-size-18);
    line-height: 22px;
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-5 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: medium;
    font-weight: var(--unnamed-font-weight-medium);
    font-size: 18px;
    font-size: var(--unnamed-font-size-18);
    line-height: 30px;
    line-height: var(--unnamed-line-spacing-30);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-6 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: 12px;
    font-size: var(--unnamed-font-size-12);
    line-height: 15px;
    line-height: var(--unnamed-line-spacing-15);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-7 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: 16px;
    font-size: var(--unnamed-font-size-16);
    line-height: 19px;
    line-height: var(--unnamed-line-spacing-19);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-8 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: 20px;
    font-size: var(--unnamed-font-size-20);
    line-height: 24px;
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-9 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: 20px;
    font-size: var(--unnamed-font-size-20);
    line-height: 24px;
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-10 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: 26px;
    font-size: var(--unnamed-font-size-26);
    line-height: 32px;
    line-height: var(--unnamed-line-spacing-32);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-11 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: 26px;
    font-size: var(--unnamed-font-size-26);
    line-height: 32px;
    line-height: var(--unnamed-line-spacing-32);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-12 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: 36px;
    font-size: var(--unnamed-font-size-36);
    line-height: 44px;
    line-height: var(--unnamed-line-spacing-44);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-13 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: bold;
    font-weight: var(--unnamed-font-weight-bold);
    font-size: 36px;
    font-size: var(--unnamed-font-size-36);
    line-height: 44px;
    line-height: var(--unnamed-line-spacing-44);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-14 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: normal;
    font-weight: var(--unnamed-font-weight-normal);
    font-size: 14px;
    font-size: var(--unnamed-font-size-14);
    line-height: 18px;
    line-height: var(--unnamed-line-spacing-18);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-15 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: normal;
    font-weight: var(--unnamed-font-weight-normal);
    font-size: 14px;
    font-size: var(--unnamed-font-size-14);
    line-height: 18px;
    line-height: var(--unnamed-line-spacing-18);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-16 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: normal;
    font-weight: var(--unnamed-font-weight-normal);
    font-size: 18px;
    font-size: var(--unnamed-font-size-18);
    line-height: 22px;
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-17 {
    font-family: Montserrat;
    font-family: var(--unnamed-font-family-montserrat);
    font-style: normal;
    font-style: var(--unnamed-font-style-normal);
    font-weight: normal;
    font-weight: var(--unnamed-font-weight-normal);
    font-size: 18px;
    font-size: var(--unnamed-font-size-18);
    line-height: 22px;
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: 0px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
/* @font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Regular.ttf");
} */
#vertical-spacer {
    flex-direction: column;
    flex-grow: 1;
}

#arrow-img{
    width: 9px;
    object-fit: contain;
    height: 7px;
    fill: white;
    margin: 9px 10px 0px 8px;
}

.border {
    position: absolute;
    display: none;
    width: 1px;
    height: 25px;
    border: 1px solid var(--white);
    border: 1px solid #FFFFFF;
    margin-right: 20px;
    opacity: 1;
}

.border-show {
    position: absolute;
    display: block;
    width: 3px;
    height: 25px;
    border: 1px solid var(--white);
    border: 1px solid #FFFFFF;
    margin-right: 20px;
    opacity: 1;
    background: white;
    border-radius: 13%;
}

@media only screen and (max-width: 768px) {
    .side-menu {
        width: 0px !important;
        padding-right: 0px !important;
        display: none !important;
    }

    .closeMobileMenuBtn {
        color: white !important;
        margin-left: auto !important;
    }

    .sideMenuHeader {
        width: 100% !important
    }
}

.mobileMenu {
    width: 100% !important;
    display: flex !important;
    z-index: 1300;
}
  
.side-menu {
    overflow-x: hidden;
    background: var(--blue-menu);
    top: 0;
    left: 0;
    width: var(--side-menu-width);
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.sideMenuHeader {
   
    margin-top: 20px;
    width: var(--side-menu-width);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 11px;
    padding-right: 25px;
}


.headerImg {
    margin-right: auto;
    width: 33px;
    height: 32px;
    margin-bottom: 0px;
}
.titleHeader {
    font: normal normal 600 16px/19px Montserrat;
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 10px;
}

.item {
    cursor: pointer;
}

.holders{
    margin: 100px 0% 0% 0%;
}

.imageHeader {
    width: 40;
    height: 40;
}

.menu-selected {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.menu-not-selected {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.5;
}



.title {
    font: normal normal 600 16px/19px Montserrat;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    margin-top: 2px;
    padding-left: 10px !important;
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 10px;
}

.submenu-div {
    display: none;
    width: 136px;
    margin-left: 35px;
    margin-right: 0;
    text-align: center;
}

.submenu-div-show {
    display: block;
    margin-left: 60px;
    text-align: left;
    margin-bottom: 15px;
}

.submenu-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: center;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.5;
}

.submenu-selected {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: center;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}


.icon {
    width: 25px;
    height: 25px;
    margin-left: 15px;
}

.menu-holder {
    display: flex;
    flex-direction: column;
}

.skeletonMenu {
    width: 90%;
    height: 100px !important;
    margin-left: 12px;
    margin-bottom: -18px !important;
}

.buildVersion {
    font: inherit !important;
    font-family: var(--unnamed-font-family-montserrat);
    color: white;
    font-size: 13px !important;
    margin-left: 12px;
}
.profileSpan {
    padding-left: 7px !important;
    align-self: center;
}

.profileTitle {
    color: "white";
    font: normal normal 600 16px/19px Montserrat;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 5px;
}

.profileIcon {
    color: white;
    padding-left: 12px;
    font-size: 2.4rem !important;
}

.container {
    cursor: pointer;
    padding-left: 5px !important;
    display: flex !important;
    align-items: center;    
}

.userPhoto {
    object-fit: cover;
    width: 35px !important;
    height: 35px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 2.4rem !important;
    border-radius: 50%;
    align-self: center;
    margin-left: 7px;
}

.border-show-profile {
    align-self: center;
    position: absolute;
    width: 3px;
    height: 25px;
    border: 1px solid var(--white);
    border: 1px solid #FFFFFF;
    opacity: 1;
    background: white;
    border-radius: 13%;
}
.marginRightAuto {
    margin-right: auto;
}
.greenPercentage {
    color: #5FAC3D !important;
}

.redPercentage {
    color: #CE4242 !important;
}

.bluePercentage {
    color: #00CCF2 !important;
}

.percentageDiv {
    margin-top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 44px;
}

.main-indicator-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    margin-left: 0 !important;
    font-style: normal !important;
    font-weight: 400;
    margin-top: 8px;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.sub-indicator-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    margin-left: 0 !important;
    font-style: normal !important;
    font-weight: 400;
    margin-top: -5px;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
    font-size: 20px;
}

.main-indicator-subtitle {
    text-align: left;
    margin-left: 0 !important;
    font-weight: normal;
    white-space: normal;
    overflow: hidden;
    display: none;
    margin-top: 10px;
    text-overflow: ellipsis;
    font: 14px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.main-indicator-date {
    text-align: left;
    margin-left: 0 !important;
    font-weight: normal;
    white-space: normal;
    overflow: hidden;
    margin-top: 10px;
    text-overflow: ellipsis;
    font: 14px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.arrow-img.a {
    fill: #2D62ED;
}

.indicator-holder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}

.indicatorParentHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media only screen and (max-width: 400px) {
    .indicator-card {
        min-width: auto !important;
    }
}

@media only screen and (max-width: 1100px) {
    .indicator-card {
        width: 100% !important;
        flex-basis: auto !important;
    }
}

@media only screen and (max-width: 1830px) and (min-width: 1100px) {
    .indicator-card  {
        flex-grow: initial !important;
        flex-basis: 49% !important;
    }
}

@media only screen and (min-width: 1830px) {
    .indicator-card {
        flex-grow: initial !important;
        flex-basis: 24% !important;
    }
}


  
  
.indicator-card {
    min-width: 360px;
    flex-basis: 25%;
    width: auto;
    margin-bottom: 56px;
    height: auto;
    display: flex;
    flex-grow: 1;   
    flex-direction: column;
    background: var(--white) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    
}

.indicator-title {
    width: 150px;
    height: 32px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    font: normal normal normal 26px/32px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.value-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-36)/var(--unnamed-line-spacing-44) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    font-weight: bold;
    font-size: 28px;
    font: "Montserrat";
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
    height: 44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.option-item:hover  {
    background-color: #2D62ED;
}

.option-item:hover > span  {
    color: white;
}

.option-title {
    width: 125px;
    height: 32px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    font: normal normal normal 14px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.percentage-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-36)/var(--unnamed-line-spacing-44) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    font: "Montserrat";
    margin-left: 5px;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
    margin-top: 5px;
    white-space: nowrap;
    margin-right: 10px;
}

.rotate180 {
    transform: rotate(180deg);
}

.status-img {
    object-fit: contain;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    margin-bottom: 0px;
}

.settings-img {
    width: 6px;
    height: 20px;
    position: relative;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: 0px;
    cursor: pointer;
    margin-bottom: 0px;
}

@media only screen and (max-width: 450px) {
    .chart-title {
        margin-left: 0px !important;
    }

    .chart-card .topHolder {
        display: flex !important;
        flex-wrap: wrap;
        height: 84px !important;
        justify-content: flex-end;
    }

    
}

.backBtnChart {
    margin-left: -15px !important;
}

.topHolder {
    padding-top: 19px;
    padding-left: 25px;
    padding-right: 5px;
    width: 100%;
    display: inline-flex;
    height: 46px;
    align-items: center;
}

.topHolder > span {
    margin-right: auto;
}

.entityTitle {
    font: 20px Montserrat;
    font-weight: bold;
    letter-spacing: 0px;
    opacity: 1;
}

.noContentTitle {
    font-size: 26px;
    text-align: center;
}

.noContentHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.indicatorSkeleton {
    height: 80px !important;
    margin-left: 10px;
    margin-right: 10px;
}
.profileSpan {
    padding-left: 7px !important;
}

.profileTitle {
    color: "white";
    font: normal normal 600 16px/19px Montserrat;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 5px;
}

.profileIcon {
    color: white;
    padding-left: 12px;
    font-size: 2.4rem !important;
}

.container {
    cursor: pointer;
    padding-left: 5px !important;
    display: flex !important;
    align-items: center;    
}

.logoutIcon {
    margin-left: 15px;
}
.action-div {
    width: 20%;
    margin-top: 20px;
    margin-left: 10%;
}

.spacer {
    flex-grow: 1;
}

.MuiIcon-root {
    overflow: visible !important;
}

@media only screen and (max-width: 768px) {
    .top-bar {
        padding-right: 0px !important;
        left: 0px !important;
    }

    .hamburguer {
        position: absolute !important;
        left: 5px;
    }
}

@media only screen and (min-width: 769px) {
    .top-bar {
        left: var(--side-menu-width) !important;
        padding-right: var(--side-menu-width) !important;
    }

    .hamburguer {
        display: none !important;
    }
}

.hamburguer {

}

.top-bar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    display: inline-flex;
    background: var(--white) 0% 0% no-repeat padding-box !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    opacity: 1;
}

.search-border {
    border: 1px solid var(--text);
    border: 1px solid #272D3B;
    width: 35%;
    height: 30px;
    margin-top: 20px;
    border-radius: 25px;
    opacity: 1;
}


.header {
    display: inline-flex;
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    margin-left: 10%;
    margin-top: 10%;
}

.notification-icon {
    width: 24px;
    height: 30px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;

}

.pointer {
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .logout-icon {
        position: absolute !important;
        right: -20px !important;
    }
}

.logout-icon {
    width: 28px;
    height: 30px;
    padding: 22px !important;
    margin-right: 40px !important;
    opacity: 1;
}

.search-icon {
    width: 19px;
    height: 19px;
    margin: 5px 5px 5px 15px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
}

.search-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    font-size: 16px;
    position: absolute;
    margin: 5px 5px 5px 5px;
    font: normal normal normal 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.topBarTitle {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;   
    margin-left: 46px !important;
    font-weight: bold  !important;
    font-size: 26px  !important;
    letter-spacing: 0px  !important;
    color: #272D3B;
    opacity: 1;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font: 112.5%/1.45em georgia, serif, sans-serif;
  box-sizing: border-box;
  overflow-y: scroll;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: hsla(0, 0%, 0%, 0.8);
  font-family: georgia, serif;
  font-weight: normal;
  word-wrap: break-word;
  -webkit-font-kerning: normal;
          font-kerning: normal;
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt", "kern";
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
  text-decoration: none;
}
b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 2.25rem;
  line-height: 1.1;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: 1em;
}
figure {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
hr {
  box-sizing: content-box;
  overflow: visible;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: calc(1.45rem - 1px);
  background: hsla(0, 0%, 0%, 0.2);
  border: none;
  height: 1px;
}
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: 700;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid silver;
  padding: 0.35em 0.625em 0.75em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
* {
  box-sizing: inherit;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}
h2 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.62671rem;
  line-height: 1.1;
}
h3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.38316rem;
  line-height: 1.1;
}
h4 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  line-height: 1.1;
}
h5 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.85028rem;
  line-height: 1.1;
}
h6 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.78405rem;
  line-height: 1.1;
}
hgroup {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
ul {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  list-style-position: outside;
  list-style-image: none;
}
ol {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  list-style-position: outside;
  list-style-image: none;
}
dl {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
dd {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
pre {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 1.45rem;
  font-size: 0.85rem;
  line-height: 1.42;
  background: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  overflow: auto;
  word-wrap: normal;
  padding: 1.45rem;
}
table {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  font-size: 1rem;
  line-height: 1.45rem;
  border-collapse: collapse;
  width: 100%;
}
blockquote {
  margin-left: 1.45rem;
  margin-right: 1.45rem;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
form {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
noscript {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
iframe {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
address {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
b {
  font-weight: bold;
}
strong {
  font-weight: bold;
}
dt {
  font-weight: bold;
}
th {
  font-weight: bold;
}
li {
  margin-bottom: calc(1.45rem / 2);
}
ol li {
  padding-left: 0;
}
ul li {
  padding-left: 0;
}
li > ol {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2);
}
li > ul {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2);
}
blockquote *:last-child {
  margin-bottom: 0;
}
li *:last-child {
  margin-bottom: 0;
}
p *:last-child {
  margin-bottom: 0;
}
li > p {
  margin-bottom: calc(1.45rem / 2);
}
code {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
kbd {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
samp {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
abbr {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}
acronym {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}
thead {
  text-align: left;
}
td,
th {
  text-align: left;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
  font-feature-settings: "tnum";
  -moz-font-feature-settings: "tnum";
  -ms-font-feature-settings: "tnum";
  -webkit-font-feature-settings: "tnum";
  padding-left: 0.96667rem;
  padding-right: 0.96667rem;
  padding-top: 0.725rem;
  padding-bottom: calc(0.725rem - 1px);
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}
tt,
code {
  background-color: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  font-family: "SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono",
    "Liberation Mono", Menlo, Courier, monospace;
  padding: 0;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}
pre code {
  background: none;
  line-height: 1.42;
}
code:before,
code:after,
tt:before,
tt:after {
  letter-spacing: -0.2em;
  content: " ";
}
pre code:before,
pre code:after,
pre tt:before,
pre tt:after {
  content: "";
}
@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}

.mainContainer {
  
}

@media only screen and (max-width: 768px) {
  .main {
      margin-left: 0px !important;
  }
}

.main {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  padding-left: var(--custom-margin);
  margin-left: var(--side-menu-width);
  margin-right: var(--custom-margin);
}

.main > span {
  display: flex;
  flex-direction: column;
}

.padded-box{
    padding:27px;
    margin-bottom:40px;
}

.box-shadow{
    box-shadow: 0px 3px 6px #272D3B33;
}
* {
    scrollbar-width: thin;
    scrollbar-color: #ecedef white;
  }
  
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  *::-webkit-scrollbar-track {
    background:white;
  }
  .side-menu::-webkit-scrollbar-track {
    background:var(--blue-menu);
  }
  
  .side-menu::-webkit-scrollbar-thumb {
    border-radius: inherit;
    border: 3px solid rgba(0,0,0,0.2);
    background-color: rgba(0, 0, 0, 0);
  }
  *::-webkit-scrollbar-thumb {
    background-color: #ecedef;
    border-radius: 100px;
    border: 3px solid #ecedef;
  }

.arrow-img {
    object-fit: cover;
    width: 10px;
    margin-left: 5px;
    fill: #2D62ED;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: auto;
    margin-bottom: auto;
}

.simple-popover {
    z-index: 1;
}
.dropHolder {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    height: 25px;
}

.listDropHolder {
    align-items: center;
    display: inline-table;
    flex-direction: row;
    margin-right: auto;
    height: 25px;
}

.drop-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-menu);
   
    text-align: left;
    font: normal normal bold 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #2D62ED;
    opacity: 1;
    cursor: pointer;
}

.drop-down {
    display: none;
    width: 100%;
    height: 56px;
    background: white;
    opacity: 1;
}

.drop-down-visible {
    display: block;
    width: auto;
    height: auto;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    z-index: 1;
    right: auto;
    margin-left: -2px;
    margin-top: 76px;
    cursor: pointer;
    padding-right: 10px;
}

.option-title {
    cursor: pointer;
}


.option-title-list {
    width: 125px;
    height: 32px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    margin-left: 0;
    margin-right: 10px;
    font: normal normal normal 14px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    cursor: pointer;
    opacity: 1;
}
.checkItem {
    
}

.checkHolder {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: scroll;
    margin-left: 10px;
    margin-right: 20px;
    padding-top: 16px;
}

.title-name {
    font: 16px Montserrat;
    font-weight: normal;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.checkHolder-indicator {
    height: 55%;
    display: inline-block;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: auto;
    padding-top: 14px;
    margin-right: 3px;
}

.checkError {
    margin-left: 25px;  
    position: absolute;
    color: red;
}
.close-modal-img {
    width: 18px;
    height: 15px;
    margin-right: 33px !important;
    margin-top: 7px !important;
    margin-bottom: auto;
    opacity: 1;
    cursor: pointer;
}

#date-picker-static {
    width: 344px;
    height: 330px;
}

.close-img-filter {
    right: 20px;
    margin-top: 8px !important;
    position: absolute !important;
    margin-left: auto !important;
    width: 18px;
    height: 15px;
    opacity: 1;
    cursor: pointer;
}

.custom-switch {
    width: 52px !important;
    height: 38px !important;
    display: inline-flex !important;
    padding: 7px 3px !important;
    margin-left: 12px !important;
    z-index: 0 !important;
    overflow: hidden !important;
    position: relative !important;
    box-sizing: border-box !important;
    flex-shrink: 0 !important;
    vertical-align: middle !important;
}

.dialog-content {
    margin-top: 30px !important;
}

.drop-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-menu);
    text-align: left;
    font: normal normal bold 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #2D62ED;
    opacity: 1;
    cursor: pointer;
}


.filter-dialog {
    height: auto;
}

.filter-modal {
    width: 380px;
}

.filter-title {
    margin-right: auto;
    font: 18px Montserrat;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    letter-spacing: 0px;
    font-weight: normal;
    color: #272D3B;
    opacity: 1;
}

.MuiSwitch-root {
    width: 52px;
    height: 38px;
    display: inline-flex;
    padding: 7px 3px;
    margin-left: 12px;
    z-index: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
    vertical-align: middle;
}


.switch-selected {
    background: var(--blue-light) 0% 0% no-repeat padding-box;
    background: #F5FBFF 0% 0% no-repeat padding-box;
    opacity: 1;
}

.switch-not-selected {
    background: transparent url(/static/media/switch_not_selected.16d64ec8.svg) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.indicator-filter-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    width: 155px;
    margin-top: 2px;
    text-align: left;
    font: normal normal bold 16px/19px Montserrat;
    color: #2D62ED !important;
    letter-spacing: 0px;
    opacity: 1;
}

.alert-dialog-title {
    margin-right: auto;
    font-family: Montserrat;
    font-weight: bold !important;
    font-size: 26px !important;
}


.chart {
    font-family: 'Montserrat';
    font-size: 14px;
}

.chartParentHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}



@media only screen and (max-width: 1090px) {
    .chartParentHolder .chart-card {
        width: 100% !important;
        flex-basis: auto !important;
    }
}

@media only screen and (min-width: 1090px) {
    .chartParentHolder .chart-card {
        flex-grow: initial !important;
        flex-basis: 49% !important;
    }
}

.chartTitleANdIcon {
    display: flex;
    margin-right: 10px !important;
    align-items: center;
}

.chart-card {
    flex-grow: 1;
    margin-bottom: 40px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
}

.chart-title {
    font-family: 'Montserrat';
    padding-right: 20px;
    margin-left: 10px;
    width: auto;
    margin-right: auto;
}

.chart .dropHolder {
    margin-right: 20px;
}

.chart-holder {
    height: 450px;
}

.recharts-default-legend li {
    margin-bottom: 0px !important;
}

.noContentTitle {
    font-size: 26px;
    text-align: center;
}

.noContentHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.chartSkeleton {
    height: 120px !important;
    margin-left: 10px;
    margin-right: 10px;
}

.recharts-legend-wrapper{
    overflow: auto;
    height: 87% !important;
}
.submit-btn {
    width: 140px;
    height: 40px;
    background: var(--blue-menu) 0% 0% no-repeat padding-box;
    background: #2D62ED 0% 0% no-repeat padding-box;
    opacity: 1;
    cursor: pointer;
}

.button-item {
    margin-right: 0px !important;
    width: 135px !important;
    height: 40px !important;
    background: var(--blue-menu) 0% 0% no-repeat padding-box !important;
    background: #2D62ED 0% 0% no-repeat padding-box !important;
    opacity: 1;
    font: var(--unnamed-font-style-normal)
    normal var(--unnamed-font-weight-600) 
    var(--unnamed-font-size-16)
    var(--unnamed-line-spacing-19) 
    var(--unnamed-font-family-montserrat) !important;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-light);
    text-align: center !important;
    margin-left: 10px !important;
    font: normal normal 600 13px/19px Montserrat !important;
    letter-spacing: 0px !important;
    color: #F5FBFF !important;
    opacity: 1;
    cursor: pointer;
    border-color: #2D62ED !important;
}

.button-item:hover {
    background: white 0% 0% no-repeat padding-box;
    color: #2D62ED;
    border-color: #2D62ED;
}


.divider {
    border: 1px solid var(--text);
    border: 1px solid #272D3B;
    opacity: 0.15;
}

.submit-btn:hover {
    background: white 0% 0% no-repeat padding-box;
    color: #2D62ED;
    border-color: #2D62ED;
}

.top-btns {
    flex-direction: row-reverse;
    display: flex;
    margin-bottom: 40px;
}
.add-file-img {
    margin-top: auto;
    margin-bottom: auto;

}

.add-file-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-menu);
    text-align: left;
    margin-left: 5%;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #2D62ED;
    opacity: 1;
}

.button-item {
    width: 135px;
    height: 40px;
    background: var(--blue-menu) 0% 0% no-repeat padding-box;
    background: #2D62ED 0% 0% no-repeat padding-box;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-light);
    text-align: center;
    margin-right: 10px;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #F5FBFF;
    opacity: 1;
    cursor: pointer;
    border-color: #2D62ED;
}

.button-item:hover {
    background: white 0% 0% no-repeat padding-box;
    color: #2D62ED;
    border-color: #2D62ED;
}

.container {
  width: auto;
  clear: both;
}

.container input {
  width: 100%;
  clear: both;
}

.close-img-modal {
    width: 22px;
    height: 22px;
    position: relative;
    left: 75%;
    margin-top: auto;
    margin-bottom: auto;
    color: #2D62ED 0% 0% no-repeat padding-box !important;
    opacity: 1;
    cursor: pointer;
}

.MuiDialog-paperWidthSm {
    height: auto;
}

.MuiDialogContent-root {
    margin: 8px 0;
}


.input-txt {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0) !important;
    color: var(--text) !important;
    text-align: left !important;
    background-color: transparent !important;
    border: 0px solid !important;
    margin-top: 0px !important;
    margin-left: 5% !important;
    margin-bottom: 0 !important;
    font: normal normal medium 16px/19px Montserrat !important;
    letter-spacing: 0px !important;
    color: #272D3B !important;
    opacity: 1 !important;
}

.input-txt:focus, input:focus{
    outline: none;
}

.label + .MuiInput-formControl {
    margin-top: 0px !important;
}

.divider {
    border: 1px solid var(--text);
    border: 1px solid #272D3B;
    opacity: 0.15;
}


.modal {
    width: 55%;
    height: 539px;
    position: absolute;
    margin: 5% 5% 0% 20%;
    z-index: 1;
    background: var(--white) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #272D3B58;
    opacity: 1;
}

.modal-hide {
    display: none;
}


.modal-show {
    display: block;

}

.modal-title {
    width: 90%;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    font: normal normal bold 26px/32px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
}

.span-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--text);
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    font: normal normal bold 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    opacity: 1;
    outline: none;
}



.submit-btn {
    width: 135px;
    height: 40px;
    color: #2D62ED;
    margin: 0 !important;
    position: absolute !important;
    top: 80%;
    left: 50%;
    transform: translate(-50%, 85%) !important;
    background: #2D62ED 0% 0% no-repeat padding-box !important;
    opacity: 1;
}

.submit-btn:hover {
    background:  #2D62ED 0% 0% no-repeat padding-box !important;;
    color: white !important;
    border-color: #2D62ED !important;
}

.top-layout {
    width: 100%;
    display: inline-flex;
    margin: 25px;
}
/* custom tabulator css */
.table, .tabulator{
    color:#272D3B;
    font-family: 'Montserrat';
    font-size:14px
}

.tabulator {
    width: 100%;
}


.tabulator .tabulator-cell{
    color:#272D3B;

}

.tabulator .tabulator-header{
    text-transform: uppercase;
    border-bottom: 1px solid #eaeaea;
}

.tabulator-row .tabulator-cell, .tabulator .tabulator-header .tabulator-col {
    border:none;
}

.tabulator .tabulator-row.tabulator-selectable:hover {
    background-color: #f3f1f1;
}

.tabulator-cell {
    margin:8px 0px;
}

@media only screen and (max-width: 480px) {
    .table-title-bar {
        flex-wrap: wrap !important;
    }
}

.table-title-text{
    white-space: nowrap;
    font-size:26px;
    font-weight: bold;
}

.table-title-bar{
    margin-bottom:42px;
    display: flex;
}


.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-content .tabulator-col-sorter .tabulator-arrow{
border:none !important;
height: 10px;
width: 10px;
}

.tabulator-col-sorter{
    background-repeat: no-repeat;
    background-position: center;
}

.table.padded-box.box-shadow.no-filter .tabulator .tabulator-header .tabulator-col {
    border: none;
}

.table.padded-box.box-shadow.no-filter .tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
    display: none;
}

.tabulator-header .tabulator-col.tabulator-sortable{
    height: auto !important;
}

.table-title-text{
    width: auto;
    display: inline-block;
}

.table-title-buttons{
    display: flex !important;
    margin-left: 10px;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    margin-top: 3px;
    flex-wrap: wrap;
}

.tabulator .tabulator-footer{
    padding: 10px 10px;
    text-align: center;
}

.table-title-button {
    display: inline-flex;
    margin-left: 10px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-menu);
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #2D62ED;
    opacity: 1;
    cursor: pointer;
}

.table-icon {
    margin-bottom: unset;
    margin-right: 5px;
    vertical-align: middle;
}

.tabulator .tabulator-footer .tabulator-page:active {
    outline: none !important;
    border: none !important;
}

.tabulator .tabulator-footer .tabulator-page:target {
    outline: none !important;
    border: none !important;
}

.tabulator .tabulator-footer .tabulator-page:focus {
    outline: none !important;
    border: none !important;
}

.tabulator .tabulator-footer .tabulator-page {
    height: 25px;
    padding: 0px 10px 0px 10px;
    border:none;
}

.tabulator .tabulator-footer .tabulator-page.active {
    color: white;
    background: #2D62ED;
}

.tabulator .tabulator-footer .tabulator-page:hover {
    color: white !important;
    background: #2D62ED !important;
}

.tabulator-header-filter input{
    border:none;
}

.tabulator-header-filter{
    border-bottom: 1px solid #2D62ED;
    margin-bottom: 6px !important;

}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
    background-color: unset;
}

.tableSkeleton {
    height: 200px !important;
    margin-top: -40px !important;
    margin-bottom: -25px !important;
}

.tabulator-edit-select-list{
    font-family: 'Montserrat';
}

.containerGrid {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-right: auto;
    margin-top: auto;
    height: 100%;
}


#imgContainer {
    position: relative;
    width: 200px;
    height: 200px;
    background:var(--blue-menu);
    border-radius: 50%;
    margin-bottom: 20px;
}


#imgContainer img {
    width: 200px;
    height: 200px;
    position: absolute;
    padding: 30px;
}
.detailHeader {
    margin-bottom: 30px !important;
}

.camera-icon {
    width: 13px;
    height: 12px;
    color: white !important;
}

.paperHolder {
    display: flex;
    font-family: 'Montserrat';
    
}

.camera-img {
    width: 15px !important;
    height: 13px !important;
}

.span {
    font-size: 14px;    
}

.detailHeaderImg {
    object-fit: cover;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-top: 29px;
    margin-left: 35px;
}

.detailItemsHolder {
    height: 100%;
    width: auto;
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    align-self: center;
    margin: 20px;
}

.detailItemOne {
    font-size: 12px;   
    font-weight: bold;
}

.detailItemTwo {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.detailItemHolder {
    display: flex;
}

.detailMainItemsHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-left: 20px;
}

.detailMainItemsHolder > span {
    font-weight: bold;
}

.paperHolderMargin {
    margin-bottom: 56px;
}


.photoImg {
    width: 85px !important;
    height: 85px !important;
    margin-left: 15px;
    background-color: #bdbdbd;
    padding-left: 3px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 2.4rem !important;
}

.MuiAvatar-colorDefault {
    color: #2D62ED;
    background-color: white;
}

.MuiAvatar-fallback {
    width: 85px !important;
    height: 65px !important;
    margin-top: 0;
    margin-bottom: 5px;
}


.upload-img {
    position: absolute;
    margin-top: 73px;
    z-index: 1;
}

#upload-photo{
    z-index: 0;
}
#paperLogin {
    background: var(--blue-white);
}
.formContainerPassword {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    max-width: 300px;
    align-items: center;
    justify-content: center;
    align-self: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

#imgContainer {
    position: relative;
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

#imgContainer div {
    position: absolute;
    width: 200px;
    height: 200px;
    background:var(--blue-menu);
    border-radius: 50%;
}

#imgContainer img {
    width: 200px;
    height: 200px;
    position: absolute;
    padding: 30px;
}

#formLogin .inputLogin {
    margin: 10px;
    height: 70px;
}

#loginBtn {
    background:var(--blue-menu);
    margin-top: 20px;
    margin: 10px;
}
.addPCBtn {
    width: 230px;
    align-self: flex-end;
    margin-bottom: 20px !important;
    margin-top: -20px !important;
}

.addDateBtn {
    margin-top: 13px !important;
    width: 278px;
    align-self: flex-start !important;
}

.dateLine {
    margin-top: -25px;
    margin-bottom: 20px;    
    display: flex;
}

.textDate {
    margin-left: 10px !important;
}

.dateDate {

}
.custom-text-margin {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.custom-date-padding {
    display: inline-flex;
}

.smaller{
    width: 33%;
    padding-right: 10px !important;
}
.addDateBtn {
    margin-top: 13px !important;
    width: 278px;
    align-self: flex-start !important;
}

.dateLine {
    margin-top: -25px;
    margin-bottom: 20px;    
    display: flex;
}

.textDate {
    margin-left: 10px !important;
}

.confirmBtn {
    margin-left: 10px !important;
    height: 50px !important;
    margin-top: 5px !important;
}

.firstLine {
    display: flex;
}

.buttonDiv {
    display: flex;
    justify-content: space-between;
}

.photoPartner {
    object-fit: cover;
    width: 130px !important;
    height: 130px !important;
    background-color: #bdbdbd;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 2.4rem !important;
    border-radius: 50%;
    align-self: center;
}

.subtitle {
    margin-top: 25px !important;
}

.divPhotoPartner {
    align-self: center;
    display: flex;
}

.camera-icon-partner {
    width: 37px !important;
    height: 37px !important;
    margin-top: 27px !important;
    color: white !important;
}

.noPhotoPartner {
    align-self: center;
    margin-left: 10px;
    font-family: 'Montserrat';
    color: red;
}

.flexSpan {
    display: flex;
    flex-direction: column;
}

.flexSpan2 {
    justify-content: center;
    position: relative;
    display: flex;
    align-self: center;
}
.inputField {
    margin-top: 25px !important;
}

.upload-user-profile {
    position: absolute;
    margin-top: 73px;
    margin-left: 47px;
    z-index: 1;
}

.simple-button {
    margin-right: 0 !important;
    width: 135px !important;
    height: 40px !important;
    background: var(--blue-menu) 0% 0% no-repeat padding-box !important;
    background: #2D62ED 0% 0% no-repeat padding-box !important;
    opacity: 1;
    font: var(--unnamed-font-style-normal)
    normal var(--unnamed-font-weight-600) 
    var(--unnamed-font-size-16)
    var(--unnamed-line-spacing-19) 
    var(--unnamed-font-family-montserrat) !important;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--blue-light);
    text-align: center !important;
    margin-left: 0 !important;
    font: normal normal 600 13px/19px Montserrat !important;
    letter-spacing: 0px !important;
    color: #F5FBFF !important;
    opacity: 1;
    cursor: pointer;
    border-color: #2D62ED !important;
    margin-top: 25px !important;
}

.photoPartner {
    object-fit: cover;
    width: 130px !important;
    height: 130px !important;
    background-color: #bdbdbd;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 2.4rem !important;
    border-radius: 50%;
    align-self: center;
}

.subtitle {
    margin-top: 25px !important;
}

.divPhotoPartner {
    align-self: center;
    display: flex;
}

.camera-icon-partner {
    width: 37px !important;
    height: 37px !important;
    margin-top: 27px !important;
    color: white !important;
}

.noPhotoPartner {
    align-self: center;
    margin-left: 10px;
    font-family: 'Montserrat';
    color: red;
}

.flexSpan {
    display: flex;
    flex-direction: column;
}

.flexSpan2 {
    justify-content: center;
    position: relative;
    display: flex;
    align-self: center;
}

.simple-button:hover {
    background: white 0% 0% no-repeat padding-box;
    color: #2D62ED;
    border-color: #2D62ED;
}
.addPCPageBtn {
    width: 252px;
    align-self: flex-end;
    margin-bottom: 20px !important;
    margin-top: -20px !important;
}

.cancelAddBtn {
    width: 75px;
    align-self: flex-start;
    margin-bottom: 20px !important;
    margin-top: -20px !important;
}
#paperLogin {
    background: var(--blue-white);
}
.formContainerGrid {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    max-width: 300px;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

#imgContainer {
    position: relative;
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

#imgContainer div {
    position: absolute;
    width: 200px;
    height: 200px;
    background:var(--blue-menu);
    border-radius: 50%;
}

#imgContainer img {
    width: 200px;
    height: 200px;
    position: absolute;
    padding: 30px;
}

#formLogin .inputLogin {
    margin: 10px;
    height: 70px;
}

#loginBtn {
    background:var(--blue-menu);
    margin-top: 20px;
    margin: 10px;
}

.forgotSpan {
    font-size: 14px;
    align-self: center;
    font-family: 'Montserrat';
}

.forgotSpan:hover {
    color: var(--blue-menu);
    cursor: pointer;
}
