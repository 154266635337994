@media only screen and (max-width: 768px) {
    :root {
        --custom-margin: 20px !important;
    }
}

:root {
    /* side menu */
    --side-menu-width: 220px;
    /* Main Containar Margins */
    --custom-margin: 67.5px;
    

    /* Colors: */
    --blue-menu: #2D62ED;
    --white: #FFFFFF;
    --dark-mode-3: #455060;
    --blue-primary: #00CCF2;
    --dark-mode-2: #29313A;
    --blue-light: #F5FBFF;
    --text: #272D3B;
    --ce4242-estado-3: #CE4242;
    ---fda820-estado-2: #FDA820;
    --5fac3d-estado-1: #5FAC3D;
    --unnamed-color-00d9a6: #00D9A6;
    --unnamed-color-ffcf64: #FFCF64;
    --unnamed-color-ff3465: #FF3465;
    --unnamed-color-003c4d: #003C4D;
    --velvet: #7D00B5;
    --unnamed-color-008cb3: #008CB3;
    --pink: #FF007C;
    --blue-light-2: #F4F7FE;
    --dark-mode-1: #181D23;
    
    /* Font/text values */
    --unnamed-font-family-montserrat: Montserrat;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-600: 600px;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-size-12: 12px;
    --unnamed-font-size-14: 14px;
    --unnamed-font-size-16: 16px;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-20: 20px;
    --unnamed-font-size-26: 26px;
    --unnamed-font-size-36: 36px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-line-spacing-15: 15px;
    --unnamed-line-spacing-18: 18px;
    --unnamed-line-spacing-19: 19px;
    --unnamed-line-spacing-22: 22px;
    --unnamed-line-spacing-24: 24px;
    --unnamed-line-spacing-30: 30px;
    --unnamed-line-spacing-32: 32px;
    --unnamed-line-spacing-44: 44px;
    }
    
    /* Character Styles */
    .unnamed-character-style-1 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-600);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-19);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-2 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-600);
    font-size: var(--unnamed-font-size-20);
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-3 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-19);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-4 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-5 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-30);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-6 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-12);
    line-height: var(--unnamed-line-spacing-15);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-7 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-19);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-8 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-20);
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-9 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-20);
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-10 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-26);
    line-height: var(--unnamed-line-spacing-32);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-11 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-26);
    line-height: var(--unnamed-line-spacing-32);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-12 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-36);
    line-height: var(--unnamed-line-spacing-44);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-13 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-36);
    line-height: var(--unnamed-line-spacing-44);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-14 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-18);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-15 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-18);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }
    .unnamed-character-style-16 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    }
    .unnamed-character-style-17 {
    font-family: var(--unnamed-font-family-montserrat);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-272d3b);
    }